import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import login, { currentAccount, logout } from 'services/user'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(login, email, password)
  if (success.success) {
    notification.success({
      message: `Logged In as ${success.type}`,
      description: '',
    })
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response.status === true) {
    const { role } = response
    console.log('response', response)
    yield put({
      type: 'user/SET_STATE',
      payload: {
        name: role,
        role,
        authorized: true,
      },
    })
  }
  yield sleep(3000)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      pauseLoading: true,
      loading: false,
    },
  })
}
function* sleep(time) {
  yield new Promise(resolve => setTimeout(resolve, time))
}
export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
