/* import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyAE5G0RI2LwzwTBizhJbnRKIKbiXQIA1dY',
  authDomain: 'cleanui-72a42.firebaseapp.com',
  databaseURL: 'https://cleanui-72a42.firebaseio.com',
  projectId: 'cleanui-72a42',
  storageBucket: 'cleanui-72a42.appspot.com',
  messagingSenderId: '583382839121',
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
const firebaseAuth = firebase.auth
export default firebaseApp

export async function login(email, password) {
  return firebaseAuth()
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        resolve(user)
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth())
}

export async function logout() {
  return firebaseAuth()
    .signOut()
    .then(() => true)
}
 */
import { notification } from 'antd'

export default function login(username, password) {
  if (username === 'superadmin@circadiatech.com' && password === 'devSleep101') {
    sessionStorage.setItem('VC_session', JSON.stringify({ Authorized: true, role: 'superAdmin', email: username }))
    return {
      success: true,
      type: 'Super Admin',
    }
  }
  if (username === 'coach@circadiatech.com' && password === 'devSleep101') {
    sessionStorage.setItem('VC_session', JSON.stringify({ Authorized: true, role: 'coach', email: username }))
    return {
      success: true,
      type: 'Coach',
    }
  }
  notification.warning({
    message: 'Invalid Login',
    description: 'Invalid Username or Password',
  })
  return {
    success: false,
    error: 'invalid Username or Password',
  }
}
export function currentAccount() {
  if (sessionStorage.getItem('VC_session')) {
    const data = JSON.parse(sessionStorage.getItem('VC_session'))
    return {
      role: data.role,
      status: true,
    }
  }
  return {
    status: false,
  }
}
export function logout() {
  sessionStorage.removeItem('VC_session')
}
